import React from "react"
// import { Link } from "gatsby"
import SEO from "../components/SEO/SEO"
import Layout from "../components/Layout"
import SmallImg from "../components/Image/SmallImg"

import { Title } from "../StyleComponents/styles"
import { FlexDiv } from "../StyleComponents/pagesStyle"

export default props => {
  return (
    <Layout location={props.location} NoADS={true}>
      <SEO
        title="404: Not found"
        description="Unitpedia 404 page"
        keywords={["Unitpedia 404 page,404"]}
      />
      <div className="container">
        <Title>
          We are Sorry Page Not Found{" "}
          <span role="img" aria-label="emoji">
            😞
          </span>
        </Title>
        <p>Wee seem to have lost this page, try one of these instead</p>
        <br />
        <FlexDiv maxWidth="350px" margin="auto">
          <SmallImg filename="404.png" alt="404 unitpedia" />
        </FlexDiv>
        <br />
        <p>NOT FOUND</p>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <br />
      </div>
      <br />
    </Layout>
  )
}
